:root {
    --color-red: #f4081f;
    --color-red-10: #da071b;
    --color-maroon: #452325;


    --color-grey: #eaeaea;
    --color-grey-dark: #545454;
    --color-grey-deep: #333;
    --color-grey-medium: #959595;
    --color-grey-shade: #aaa;
    --color-grey-tint: #d2d2d2;
    --color-grey-light: #f4f4f4;
    --color-grey-very-light: #f8f8f8;

    --color-star-buy-solid: #E47600;
    --color-star: #F7BD02;
    
    /* Gradients */
    --color-star-buy-gradient: linear-gradient(90deg, #E47600, #F7BD02);

}